import React from "react"
import css from './ProgressBar.module.css'

const ProgressBar = props => {
  return (
    <div className={css.progressbar}>
      <Filler percentage={props.percentage} />
    </div>
  )
}

const Filler = props => {
  return <div className={css.filler} style={{ width: `${props.percentage}%` }} />
}

export default ProgressBar;
