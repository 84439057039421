import React from "react"
import WarningImage from "../../images/warning-man.png"
import BusinessMan from "../../images/businessmn.png"
import TimerImage from "../../images/timer.png"
import styles from "../../css/quiz.module.css"
import ProgressBar from "../../components/ProgressBar"

const QuizHeader = React.forwardRef(({percentage}, ref) => (
  <div>
    <header>
      <h1>Parenting Style Quiz</h1>
    </header>
    <section className={styles.instructions}>
      <div className={styles.box}>
        <img className={styles.image} src={TimerImage} alt="avatar" />
        <p>Takes less than 5 minute</p>
      </div>
      <div className={styles.box}>
        <img className={styles.image} src={BusinessMan} alt="avatar" />
        <p>Answer honestly, even if you don’t like the answer</p>
      </div>
      <div className={styles.box}>
        <img className={styles.image} src={WarningImage} alt="avatar" />
        <p>Try not to leave any 'neutral' answers.</p>
      </div>
    </section>
    <section className="center">
        <p>Diana Baumrind is a clinical and developmental psychologist and she introduced three parenting styles in 1971 and later Maccoby and Latin formulated the fourth parenting style in 1983. These parenting styles have great effects on a child’s mental health</p>
    </section>
    <section ref={ref} className={styles.margin_tb}>
      <ProgressBar percentage={percentage} />
    </section>
  </div>
))
export default QuizHeader
