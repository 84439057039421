import React from "react"
import { Link } from "gatsby"
import style from "../../css/results.module.css"
import strengthPic from "../../images/strength.jpg"
import weaknessPic from "../../images/weakness.png"
import axis from "../../images/axis.png"

const ResultLayout = ({ examplePic, result, strength, weakness, example }) => (
  <div>
    <div className={style.row}>
      <div className={style.axisImg}>
        <img src={axis} alt="all of the axis" />
      </div>
    </div>
    <p>{result}</p>
    <div className={style.cta}>
      <Link className="button" to="/courses">
        BOOK COURSE NOW TO IMPROVE
      </Link>
    </div>
    <h2 className={style.center}>STRENGTHS</h2>
    <div className={style.row}>
      <div className={style.img}>
        <img src={strengthPic} alt="A arm with muscles" />
      </div>
      <div className={style.desc}>
        <p>{strength}</p>
      </div>
    </div>
    <h2 className={style.center}>WEAKNESS</h2>
    <div class={style.row}>
      <div className={style.img}>
        <img src={weaknessPic} alt="a chain of links" />
      </div>
      <div className={style.desc}>
        <p>{weakness}</p>
      </div>
    </div>
    <h2 className={style.center}>EXAMPLES</h2>
    <div class={style.row}>
      <div className={style.img}>
        <img src={examplePic} alt="a chain of links" />
      </div>
      <div className={style.desc}>
        <p>{example}</p>
      </div>
    </div>
    <div class={style.row}>
      <div className={style.desc}>
        <p><strong>References</strong><br/>Baumrind, D. (1991). The Influence of Parenting Style on Adolescent Competence and Substance Use. <i>Journal of Early Adolescence, 11(1), 56-95</i>
<br/>Morin, A. (March 25, 2019). 4 types of parenting styles and their effects on kids. Retrieved from https://www.verywellfamily.com/types-of-parenting-styles-1095045
</p>
      </div>
    </div>
    <div className={style.cta}>
      <Link className="button" to="/courses">
        BOOK COURSE NOW TO IMPROVE
      </Link>
    </div>
  </div>
)

export default ResultLayout
