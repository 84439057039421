import React, { Component } from "react"

import Layout from "../layouts"
import styles from "../css/quiz.module.css"
import SEO from "../components/seo"
import quizQuestions from "../components/quiz/questions"
import Question from "../components/quiz/Question"
import Result, {getResult} from "../components/quiz/Result"
import MailList from "../components/mailList/MailList"

import QuizHeader from "../components/quiz/QuizHeader"

class Quiz extends Component {
  constructor(props) {
    super(props)

    this.quizProgressRef = React.createRef()
    this.resultRef = React.createRef()

    this.state = {
      percentage: 10,
      allQuestions: [],
      questions: [],
      minQuestionOnPage: 0,
      questionsPerPage: 4,
      maxQuestionOnPage: 4,
      maxPages: "",
      currentPage: "",
      selectedAnswers: {},
      result: false,
      unanswered: false,
      showGrabEmail: true,
    }
    this.setNextQuestions = this.setNextQuestions.bind(this)
    this.setPreviousQuestions = this.setPreviousQuestions.bind(this)
    this.handleAnswerSelected = this.handleAnswerSelected.bind(this)
    this.viewResults = this.viewResults.bind(this)
    this.showResults = this.showResults.bind(this)
  }

  handleAnswerSelected(e) {
    const selectedAnswers = this.state.selectedAnswers
    const answer = parseInt(e.target.value)

    const qid = e.target.name
    selectedAnswers[qid] = answer
    this.setState({ selectedAnswers, unanswered: false })
  }

  calculateScore() {
    const allQuestions = this.state.allQuestions
    const answersObj = this.state.selectedAnswers
    const questions = Object.keys(answersObj)
    let answersValuesPartA = []
    let answersValuesPartB = []

    questions.forEach(key => {
      const isPartA = allQuestions.filter(q => q.id === key)[0].part === "A"
      if (isPartA) {
        answersValuesPartA.push(answersObj[key])
      } else {
        answersValuesPartB.push(answersObj[key])
      }
    })
    const partAtotal =
      answersValuesPartA.length > 0
        ? answersValuesPartA.reduce(function myFunc(total, num) {
            return total + num
          })
        : 0
    const partBtotal =
      answersValuesPartB.length > 0
        ? answersValuesPartB.reduce(function myFunc(total, num) {
            return total + num
          })
        : 0
    return {
      xAxis: partAtotal,
      yAxis: partBtotal,
    }
  }

  componentDidMount() {
    const maxPages = Math.ceil(
      quizQuestions.length / this.state.questionsPerPage
    )

    this.setState({
      allQuestions: quizQuestions,
      currentPage: 1,
      maxPages: maxPages,
      questions: quizQuestions.slice(
        this.state.minQuestionOnPage,
        this.state.maxQuestionOnPage
      ),
    })
  }

  hasPageQuestionsBeenAnswered() {
    const answersIds = Object.keys(this.state.selectedAnswers)
    let questionIds = this.state.questions.map(answer => answer.id)
    return questionIds.every(ai => answersIds.includes(ai))
  }

  setNextQuestions(e) {
    const questionsLeftToAnswer = this.hasPageQuestionsBeenAnswered()
    if (!questionsLeftToAnswer) {
      this.setState({ unanswered: true })
      return
    }
    const minQuestionOnPage = this.state.maxQuestionOnPage
    const maxQuestionOnPage =
      this.state.maxQuestionOnPage + this.state.questionsPerPage

    this.setState(
      {
        percentage: (this.state.currentPage / this.state.maxPages) * 100,
        unanswered: false,
        currentPage: this.state.currentPage + 1,
        maxQuestionOnPage: maxQuestionOnPage,
        minQuestionOnPage: minQuestionOnPage,
        questions: this.state.allQuestions.slice(
          minQuestionOnPage,
          maxQuestionOnPage
        ),
      },
      () => this.scrollToMyRef(this.quizProgressRef.current)
    )
  }

  setPreviousQuestions() {
    const minQuestionOnPage =
      this.state.maxQuestionOnPage - this.state.questionsPerPage * 2
    const maxQuestionOnPage =
      this.state.maxQuestionOnPage - this.state.questionsPerPage
    const currentPage = this.state.currentPage - 1
    this.setState({
      unanswered: false,
      currentPage,
      percentage:
        ((currentPage === 0 ? 1 : currentPage - 1) / this.state.maxPages) * 100,
      maxQuestionOnPage: maxQuestionOnPage,
      minQuestionOnPage: minQuestionOnPage,
      questions: this.state.allQuestions.slice(
        minQuestionOnPage,
        maxQuestionOnPage
      ),
    })
  }

  viewResults(e) {
    const questionsLeftToAnswer = this.hasPageQuestionsBeenAnswered()
    if (!questionsLeftToAnswer) {
      this.setState({ unanswered: true })
      return
    }
    this.setState({ result: true })
  }

  showResults(e){
    this.setState({ showGrabEmail: false })
  }

  renderResult() {
    const result = this.calculateScore()
    const parentType = getResult(result);
    this.scrollToMyRef(this.resultRef.current)
    return (
      <div ref={this.resultRef}>
        {this.state.showGrabEmail ? (
          <div>
            <header>
              <h1>RESULTS ARE ALMOST READY</h1>
            </header>
            <h3 className="center">Enter your email to view your results and subscribe to our newsletter</h3>
            <MailList className="fontLarge" addToListComplete={this.showResults} parentType={parentType.name} />
            {/* <button class={styles.skip} onClick={this.showResults}>
      I'll do this later, show me my results
    </button> */}
          </div>
        ) : (
            <Result score={result} />
        )}
      </div>
    )
  }

  scrollToMyRef = ref => window.scrollTo(0, ref.offsetTop)

  renderQuiz() {
    return (
      <div ref={this.resultRef}>
        <SEO title="Parenting Style Quiz" description="Complete this 5 minutes quiz to see what kind of Parent you are" />
        <QuizHeader ref={this.quizProgressRef} percentage={this.state.percentage} />
        <section className={styles.quizbox}>
          {this.state.questions.map(question => {
            return (
              <Question
                onAnswerSelected={this.handleAnswerSelected}
                key={question.id}
                id={question.id}
                question={question.question}
                answerScale={question.answerScale}
                part={question.part}
                selectedAnswers={this.state.selectedAnswers}
              />
            )
          })}
          {this.state.unanswered ? (
            <p className={styles.error}>Please answer all questions</p>
          ) : null}
          <div className={styles.quizButtonRow}>
            {this.state.currentPage > 1 ? (
              <button
                className="button btn-wide"
                onClick={this.setPreviousQuestions}
              >
                BACK
              </button>
            ) : null}
            {this.state.currentPage < this.state.maxPages ? (
              <button
                className="button btn-wide"
                onClick={this.setNextQuestions}
              >
                NEXT
              </button>
            ) : null}
            {this.state.currentPage === this.state.maxPages ? (
              <button
                className="button button_secondary btn-wide"
                onClick={this.viewResults}
              >
                SUBMIT
              </button>
            ) : null}
          </div>
        </section>
      </div>
    )
  }

  render() {
    return (
      <Layout>
        {this.state.result ? this.renderResult() : this.renderQuiz()}
      </Layout>
    )
  }
}

export default Quiz
