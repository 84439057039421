import React from "react"
import styles from "../../css/quiz.module.css"

function Question(props) {
  return (
    <div className={styles.question}>
      <p>{props.question}</p>
      <div className={styles.quizradiogroup}>
        <span>NEVER</span>
        <input
          className={styles.quizradio}
          type="radio"
          name={props.id}
          value={props.answerScale[0]}
          onChange={props.onAnswerSelected}
          checked={props.answerScale[0] === props.selectedAnswers[props.id]}
        />
        <input
          className={styles.quizradio}
          type="radio"
          name={props.id}
          value={props.answerScale[1]}
          onChange={props.onAnswerSelected}
          checked={props.answerScale[1] === props.selectedAnswers[props.id]}
        />
        <input
          className={styles.quizradio}
          type="radio"
          name={props.id}
          value={props.answerScale[2]}
          onChange={props.onAnswerSelected}
          checked={props.answerScale[2] === props.selectedAnswers[props.id]}
        />
        <input
          className={styles.quizradio}
          type="radio"
          name={props.id}
          value={props.answerScale[3]}
          onChange={props.onAnswerSelected}
          checked={props.answerScale[3] === props.selectedAnswers[props.id]}
        />
        <input
          className={styles.quizradio}
          type="radio"
          name={props.id}
          value={props.answerScale[4]}
          onChange={props.onAnswerSelected}
          checked={props.answerScale[4] === props.selectedAnswers[props.id]}
        />
        <span>ALWAYS</span>
      </div>
    </div>
  )
}

export default Question
