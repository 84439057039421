import React from "react"
import {
  Authoritarian,
  Authoritive,
  Neglectful,
  Indulgent,
} from "./ParentTypes"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

const parentType = {
  25: { name: "Authoritarian", desc: Authoritarian },
  45: { name: "Authoritative", desc: Authoritive },
  80: { name: "Neglectful", desc: Neglectful },
  100: { name: "Indulgent", desc: Indulgent },
}

const calcTotal = ({ xAxis, yAxis }) => {
  const xPoints = xAxis < 45 ? 60 : 5
  const yPoints = yAxis < 45 ? 20 : 40
  return xPoints + yPoints
}

const getResult = score => {
  const points = calcTotal(score)
  return parentType[points]
}

const Result = ({ score }) => {
  trackCustomEvent({
    // string - required - The object that was interacted with (e.g.video)
    category: "Quiz Complete",
    // string - required - Type of interaction (e.g. 'play')
    action: "Click",
  })
  const res = getResult(score)

  return (
    <div>
      <section>
        <header>
          <h1>YOUR PARENTING STYLE IS <br/>{res.name}</h1>
        </header>
        <res.desc />
      </section>
    </div>
  )
}

export { getResult }
export default Result
