var quizQuestions = [
  {
    id: "Q1",
    question: "I have a friendly chat with my child",
    answerScale: [0, 1, 2, 3, 4],
    part: "A"
  },
  {
    id: "Q2",
    question: "I ask my child about their school day",
    answerScale: [0, 1, 2, 3, 4],
    part: "A"
  },
  {
    id: "Q3",
    question: "I talk to my child about their friends",
    answerScale: [0, 1, 2, 3, 4],
    part: "A"
  },
  {
    id: "Q4",
    question: "When I am at home I am engaging with my children and not busy/distracted by my phone",
    answerScale: [0, 1, 2, 3, 4],
    part: "A"
  },
  {
    id: "Q5",
    question: "I give 1-2-1 personal quality time to each one of my children",
    answerScale: [0, 1, 2, 3, 4],
    part: "A"
  },
  {
    id: "Q6",
    question: "I use descriptive praise when I compliment my child after they do something well",
    answerScale: [0, 1, 2, 3, 4],
    part: "A"
  },
  {
    id: "Q7",
    question: "I hug or kiss my child when they do something well",
    answerScale: [0, 1, 2, 3, 4],
    part: "A"
  },
  {
    id: "Q8",
    question: "I acknowledge good behaviour of my child through praise or reward",
    answerScale: [0, 1, 2, 3, 4],
    part: "A"
  },
  {
    id: "Q9",
    question: "I encourage autonomy in my child by allowing them to make choices",
    answerScale: [0, 1, 2, 3, 4],
    part: "A"
  },
  {
    id: "Q10",
    question: "I tend to say NO a lot to my children’s requests",
    answerScale: [4, 3, 2, 1, 0],
    part: "A"
  },
  {
    id: "Q11",
    question: "I smack my child with my hand when they do something wrong",
    answerScale: [4, 3, 2, 1, 0],
    part: "A"
  },
  {
    id: "Q12",
    question: "I slap/pinch/pull my child when they do something wrong",
    answerScale: [4, 3, 2, 1, 0],
    part: "A"
  },
  {
    id: "Q13",
    question: "I hit my child with a cane or other items/objects when they do something wrong",
    answerScale: [4, 3, 2, 1, 0],
    part: "A"
  },
  {
    id: "Q14",
    question: "I find it hard to control my anger when my children have done something wrong",
    answerScale: [4, 3, 2, 1, 0],
    part: "A"
  },
  {
    id: "Q15",
    question: "I yell or scream at my child when  they have done something wrong",
    answerScale: [4, 3, 2, 1, 0],
    part: "A"
  },
  {
    id: "Q16",
    question: "My child has more than 2 hours of screen time a day",
    answerScale: [4, 3, 2, 1, 0],
    part: "B"
  },
  {
    id: "Q17",
    question: "My child has unrestricted use of the internet/TV",
    answerScale: [4, 3, 2, 1, 0],
    part: "B"
  },
  {
    id: "Q18",
    question: "My child plays with friends online (or outside) that I don’t know",
    answerScale: [4, 3, 2, 1, 0],
    part: "B"
  },
  {
    id: "Q19",
    question: "I fail to check that my child comes home at the time they were supposed to",
    answerScale: [4, 3, 2, 1, 0],
    part: "B"
  },
  {
    id: "Q20",
    question: "My child has a smartphone that is not regulated or checked",
    answerScale: [4, 3, 2, 1, 0],
    part: "B"
  },
  {
    id: "Q21",
    question: "I threaten to punish my child and then do not actually punish them",
    answerScale: [4, 3, 2, 1, 0],
    part: "B"
  },
  {
    id: "Q22",
    question: "My child talks me out of being punished after they did something wrong",
    answerScale: [4, 3, 2, 1, 0],
    part: "B"
  },
  {
    id: "Q23",
    question: "I feel that getting my child to obey me is more trouble than it’s worth",
    answerScale: [4, 3, 2, 1, 0],
    part: "B"
  },
  {
    id: "Q24",
    question: "I let my child out of a punishment early (e.g. lift restrictions earlier than I originally said)",
    answerScale: [4, 3, 2, 1, 0],
    part: "25"
  },
  {
    id: "Q25",
    question: "My child is not punished when they have done something wrong",
    answerScale: [4, 3, 2, 1, 0],
    part: "B"
  },
  {
    id: "Q26",
    question: "I ignore my child during tantrums",
    answerScale: [0, 1, 2, 3, 4],
    part: "B"
  },
  {
    id: "Q27",
    question: "I take away privileges or money from my child as a punishment",
    answerScale: [0, 1, 2, 3, 4],
    part: "B"
  },
  {
    id: "Q28",
    question: "I send my child to their room as a punishment",
    answerScale: [0, 1, 2, 3, 4],
    part: "B"
  },
  {
    id: "Q29",
    question: "I calmly explain to my child why their behaviour was wrong when they misbehave",
    answerScale: [0, 1, 2, 3, 4],
    part: "B"
  },
  {
    id: "Q30",
    question: "I use time out (e.g. make them sit/stand in a corner/stairs) as a punishment",
    answerScale: [0, 1, 2, 3, 4],
    part: "B"
  },
]

export default quizQuestions
