import React from "react"
import ResultLayout from './ResultLayout'
import authoritativeEx from "../../images/authoritative.jpg"
import authoritarianEx from "../../images/authoritarian.jpg"
import neglectfulEx from "../../images/neglectful.jpg"
import indulgentEx from "../../images/indulgent.jpg"

export const Authoritive = () => {
const result = `Authoritative parents expect good behaviour from their children, but they will try to gently and affectionately guide them rather than being forceful or cruel. Authoritative parents tend to be flexible and rely on reason instead of force, they favour supportive discipline. Parents show warmth and are responsive to the needs of their children. They will communicate with their children to share their problems and give advice accordingly. They appreciate and try to encourage their children to be independent. They use praise and reward to promote good behaviour in their children. Authoritative parents try to set reasonable expectations and understand the feelings of their children. They also try to take their children’s opinion on some matters. They invest energy and time to protect their children from behavioral problems.`;
const strength = `Children of the authoritative parents are usually self-reliant, have high self-esteem with a large circle of peers.  They tend to be happy and successful. They can be good at problem-solving strategies and decision making. Children can end up confident and assertive, socially responsible and cooperative.  They usually have clear goals and do well in education.`;
const weakness = `This parenting style is easy to discuss but can be very difficult to implement and maintain. Striking the right balance can be difficult, and rarely can parents keep this style going in the face of multiple pressures coming together simultaneously. If anything goes wrong or if parents start slipping into an authoritarian style, it can result in feelings of guilt and failure.  `;
const example = `Gil Buckman and Karen are the two characters in the movie Parenthood (1989) who apply the authoritative parenting style in which both are responsive to their children. They are assertive and supportive rather than restrictive. `;
return (
  <div>
    <ResultLayout examplePic={authoritativeEx} result={result} strength={strength} weakness={weakness} example={example}  />    
  </div>
)}

export const Authoritarian = () => {
  const result = `Authoritarian parents tend to be controlling and they establish their style of parenting upon rigid rules. Parents often fall short in responding to their child’s needs yet demand a lot from them. They focus on discipline and obedience were the child has to do what he/she is told. Authoritarian parents in general do not create an environment that allows their children to problem solve. There can be a lack of trust and/or appreciation of their children’s achievement. They punish their children for their mistakes where they could have instead taught them to make the right choices. As a result, children can be at higher risk of lower self-esteem because they are led to believe their opinions and ideas are not valued.`;
  const strength = `Children of authoritarian parents are generally well behaved due to the strictness of their parents. They know the difference between good and bad. They live in an environment that focuses on safety and this protects them from making harmful decisions. Authoritarian parents create a plan with their children having to follow the step by step instructions set, that’s why they have clear goals and are able to achieve them. This parenting style creates a sense of responsibility. `;
  const weakness = `Children can become hostile and feel anger toward their parents. They can suffer from a lack of creativity and are unable to think about how to do things better. Authoritarian parents are strict, so their children can be prone to lying to avoid bad consequences. This can lead to children having a low level of confidence and low self-esteem. As a result their personality becomes introvert and generally they are not involved in debate or discussion. There is a lack of flexibility given in any situation.  Children can become highly insecure and do not accept failure. This all can result in lot of behavioural problems i.e. anxiety, depression and even obesity.`;
  const example = `Parenthood (1989) is a movie in which Susan and Nathan are two characters who use the authoritarian parenting style by keeping their environment strict. They are highly demanding and expect their orders to be followed without explanation (Baumrind, 1991, p. 62). `;

  return (
    <div>
    <ResultLayout examplePic={authoritarianEx} result={result} strength={strength} weakness={weakness} example={example}  />    
  </div>
  )}

export const Neglectful = () => {
  const result = `Neglectful parents may be careless and can lack knowledge about their children’s need. This comes across as parents not placing the welfare of their children as a high priority. They may lack in investing time and energy to share the problems of their children. Affectionate practices can be limited and this may be expressed through practices such as leaving the child on their own. This style of parenting is usually due to their own problems and tough routine impacting on their ability to parent.`;
  const strength = `This parenting style can make children independent and self-reliant. They learn to take care of themselves from a very early stage of their life.`;
  const weakness = `Children of neglectful parents can become poor in almost all the domains of their life. They tend to struggle with low self-esteem and can be less socially competent. This usually leaves children confused lacking guidance and can often be involved in some unethical behaviours. The result of which are that children can be emotionally immature and may engage in antisocial behaviour.`;
  const example = `Larry is a character in the movie Parenthood (1989) and his character explains the basic points of neglectful parenting. According to the Baumrind (1991), he shows low demandingness and low sense of responsiveness.`;

  return (
  <div>
    <ResultLayout examplePic={neglectfulEx} result={result} strength={strength} weakness={weakness} example={example}  />    
  </div>
)}

export const Indulgent = () => {
  const result = `Indulgent (Permissive) parents show the emotional warmth and high responsiveness to the needs of their children. But parents tend to be more lenient and as a result they may not have clear boundaries for their children. They may act as a friend more than a parent which can lead to them allowing their children to decide what they feel is appropriate behaviour. Parents encourage their children to share the problems but they may not discourage their children’s bad choices and behaviours.`;
  const strength = `Children who grow with the indulgent (permissive) parenting style tend to rely on their own decisions and never complain. They are more independent and autonomous.`;
  const weakness = `These children tend to grow up frustrated and do not understand society’s expectations. They can have a lack of self-control and are unable to mould their wishes to others’ expectations. These children often have poor control over their emotions and may encounter difficulties relating with peer relationships. They often have low self-esteem and could struggle academically. This results in them exhibiting more behavioural problems and are at high risk of health issues i.e. obesity.`;
  const example = `Helen is a character in the movie Parenthood (1989), who is divorced and as a single parent for her two children, she shows herself as an indulgent (permissive) parent.  According to this style of parenting, Helen is lenient and does not require mature behaviour and avoids confrontation.`;

  return (
    <div>
    <ResultLayout examplePic={indulgentEx} result={result} strength={strength} weakness={weakness} example={example}  />    
  </div>
)}
